<template>
  <ContentContainer>
    <template #title>
      <h3>{{ getClientInContext.client_name }}'s Workflows</h3>
    </template>
    <template #header>
      <div>
        <div class="d-flex">
          <div class="dropdown mr-3">
            <a
              :disabled="loading"
              class="btn btn-light dropdown-toggle"
              ref="workflow_status_filter"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              >Filter By Status: {{ workflowStatusFilters[statusFilter] }}</a
            >

            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <template
                v-if="
                  workflowStatusFilters &&
                    Object.keys(workflowStatusFilters).length > 1
                "
              >
                <li
                  v-for="workflowStatus in Object.keys(workflowStatusFilters)"
                  :key="workflowStatus"
                >
                  <a
                    :disabled="loading"
                    class="dropdown-item"
                    @click="searchWorkflows(workflowStatus)"
                    >{{ workflowStatusFilters[workflowStatus] }}</a
                  >
                </li>
              </template>
            </ul>
          </div>
          <button class="btn btn-blue" v-b-modal.workflow-dropdown>
            Add new workflow
          </button>
        </div>
      </div>
    </template>
    <div class="text-center m-4" v-if="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <b-container v-else-if="!this.allowWork">
      <div class="row justify-content-between">
        <h5>
          To be able to create workflows, you must first create notification
          templates for SMS and E-mail. Please create and finalize templates
          named "Default SMS" and "Default E-Mail" before continuing.
        </h5>
      </div>
    </b-container>
    <div v-else-if="Array.isArray(getWorkflows)">
      <b-modal
        id="workflow-dropdown"
        block
        class="w-100 pb-3"
        variant="dark"
        title="Add new workflow"
      >
        <form @submit.prevent="createWorkflow" class="col">
          <label class="text-left w-100 h6">Workflow title:</label>
          <input
            v-model="createWorkflowForm.title"
            placeholder="Type something..."
            class="form-control form-control-sm w-100"
          />
          <template
            v-if="
              (getUserIsClientAdmin || getUserIsAdministrator) &&
                assigneeOptions.length > 1
            "
          >
            <div class="mt-2 mb-2">
              <label class="w-100 text-left">Please Select a User: </label>
              <b-form-select
                size="sm"
                class="w-100"
                v-model="createWorkflowForm.managerID"
                :options="assigneeOptions"
                @change="createWorkflowForm.followupWorkflowUUID = null"
              ></b-form-select>
            </div>
            <div
              class="mt-2 mb-2"
              v-if="
                createWorkflowForm.managerID &&
                  Array.isArray(
                    clientManagerWorkflows[createWorkflowForm.managerID]
                  ) &&
                  clientManagerWorkflows[createWorkflowForm.managerID].length >
                    0
              "
            >
              <label class="w-100 text-left">Workflow Follow-up:</label>
              <b-form-select
                size="sm"
                class="w-100"
                v-model="createWorkflowForm.followupWorkflowUUID"
                :options="clientManagerWorkflows[createWorkflowForm.managerID]"
              ></b-form-select>
            </div>
          </template>
          <template v-else-if="getUserIsManager">
            <div
              class="mt-2 mb-2"
              v-if="
                managerManagerID &&
                  Array.isArray(clientManagerWorkflows[managerManagerID]) &&
                  clientManagerWorkflows[managerManagerID].length > 0
              "
            >
              <label class="w-100 text-left">Workflow Follow-up:</label>
              <b-form-select
                size="sm"
                class="w-100"
                v-model="createWorkflowForm.followupWorkflowUUID"
                :options="clientManagerWorkflows[managerManagerID]"
              ></b-form-select>
            </div>
          </template>
          <div class="mt-2 mb-2" v-if="schedulingConsumerOptions.length > 0">
            <label class="w-100 text-left">Workflow Scheduling: </label>
            <b-form-select
              size="sm"
              class="w-100"
              v-model="createWorkflowForm.offsetMeta"
              :options="schedulingConsumerOptions"
            ></b-form-select>
          </div>
          <div class="mt-2 mb-2">
            <label class="w-100 text-left">Timeline Start Offset: </label>
            <b-form-select
              size="sm"
              class="w-100"
              v-model="createWorkflowForm.offsetChoice"
              :options="offsetChoiceOptions"
            ></b-form-select>
            <template v-if="createWorkflowForm.offsetChoice == 'custom'">
              <input
                type="number"
                v-model="createWorkflowForm.offsetModifierAmount"
                placeholder="Amount of.."
                class="form-control form-control-sm w-100"
              />
              <b-form-select
                size="sm"
                class="w-100"
                v-model="createWorkflowForm.offsetModifier"
                :options="offsetModifierOptions"
              ></b-form-select>
            </template>
            <b-form-select
              v-if="createWorkflowForm.offsetChoice !== 0"
              size="sm"
              class="w-100"
              v-model="createWorkflowForm.offsetModifierDirection"
              :options="offsetDirectionOptions"
            ></b-form-select>
          </div>
        </form>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-between">
            <button
              type="button"
              class="btn-outline-blue"
              @click.stop.prevent="
                (e) => {
                  $bvModal.hide('workflow-dropdown');
                }
              "
            >
              Close
            </button>
            <button
              type="submit"
              class="btn-blue"
              @click.prevent="createWorkflow"
            >
              Create Workflow
            </button>
          </div>
        </template>
      </b-modal>
      <div
        v-if="workflows && workflows.length > 0"
        class="display-table clickable"
      >
        <b-card
          v-for="(workflow, workflowIndex) in workflows"
          :key="workflow.id"
          @click="() => clickWorkflow(workflow)"
        >
          <b-row class="display-table-row">
            <b-col cols="3">{{ workflow.workflow_name }}</b-col>
            <b-col cols="2" class="assigned-to text-left">
              <div v-if="managerNames[workflow.manager_id]">
                <span>Assigned to </span>
                <strong>{{ managerNames[workflow.manager_id] }}</strong>
              </div>
              <span v-else>No assigned managers</span></b-col
            >
            <b-col cols="2" class="assigned-to text-left">
              <div>
                <span>Created </span>
                <strong>{{
                  formatWorkflowDate(workflow.created_datetime)
                }}</strong>
              </div>
            </b-col>
            <b-col cols="2" class="assigned-to text-left">
              <div>
                <span>Last Updated </span>
                <strong>{{
                  formatWorkflowDate(workflow.modified_datetime)
                }}</strong>
              </div>
            </b-col>
            <b-col cols="3">
              <div class="d-flex justify-content-end">
                <template v-if="!workflowsAreArchivedFilter">
                  <div
                    class="badge badge-success mr-1"
                    v-if="workflow.workflow_finalized"
                  >
                    {{ workflowStatusFilters.finalized }}
                  </div>
                  <div class="badge badge-warning mr-1" v-else>
                    {{ workflowStatusFilters.incomplete }}
                  </div>
                  <div
                    class="badge badge-info mr-1"
                    v-if="workflow.workflow_version < workflow.latest_version"
                  >
                    {{ workflowStatusFilters.newversion }}
                  </div>
                  <div class="dropdown">
                    <button
                      class="task-dropdown-btn btn ellipsis-btn"
                      type="button"
                      :id="'workflowActions' + workflowIndex"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      @click.prevent="$event.stopPropagation()"
                    >
                      <a>&#x22EE;</a>
                    </button>
                    <ul
                      class="dropdown-menu"
                      :aria-labelledby="'workflowActions' + workflowIndex"
                    >
                      <li>
                        <a
                          class="dropdown-item"
                          @click.stop.prevent="duplicateWorkflow(workflow)"
                          ><i class="fas fa-clone"></i> Duplicate</a
                        >
                      </li>
                      <template v-if="!workflow.workflow_finalized">
                        <li>
                          <a
                            class="dropdown-item"
                            @click.stop.prevent="editWorkflow(workflow)"
                            ><i class="fas fa-edit"></i> Edit</a
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            @click.stop.prevent="finalizeWorkflow(workflow)"
                            ><i class="fas fa-check-circle"></i> Publish</a
                          >
                        </li>
                      </template>
                      <template v-if="workflow.workflow_finalized">
                        <li>
                          <a
                            class="dropdown-item"
                            @click.stop.prevent="handleNewVersion(workflow)"
                          >
                            <i class="fas fa-edit"></i>
                            {{
                              workflow.latest_version == workflow.workflow_version
                                ? "Create"
                                : "Edit"
                            }}
                            Draft
                          </a>
                        </li>
                        <li @click.stop>
                          <router-link
                            class="dropdown-item"
                            :to="{
                              name: 'ClientWorkflowPreview',
                              params: {
                                uuid: workflow.workflow_uuid,
                                version: workflow.workflow_version,
                              },
                            }"
                          >
                            <i class="fas fa-eye"></i>
                            View Published
                          </router-link>
                        </li>
                        <li
                          v-if="
                            workflow.latest_version != workflow.workflow_version
                          "
                        >
                          <a
                            class="dropdown-item"
                            @click.stop.prevent="finalizeNewVersion(workflow)"
                          >
                            <i class="fas fa-check-circle"></i>
                            Publish
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            @click.stop.prevent="archiveWorkflow(workflow)"
                            ><i class="fas fa-check-circle"></i> Archive</a
                          >
                        </li>
                      </template>
                    </ul>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="badge badge-error mr-1"
                  >
                    {{ workflowStatusFilters.archived }}
                  </div>
                </template>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <div v-else>
        No workflows found.
      </div>
      <div class="d-flex w-100 mt-3">
        <ul class="pagination mx-auto">
          <li class="page-item" v-if="pagination.page > 1">
            <a
              class="page-link"
              href="javascript:void(0)"
              @click="setPage(pagination.page - 1)"
              >Previous</a
            >
          </li>
          <!-- pages 1 to 4 -->
          <template
            v-for="i in paginationRange(1, Math.min(pagination.totalPages, 4))"
          >
            <li
              :class="'page-item' + (pagination.page == i ? ' active' : '')"
              :key="'pagination_top_' + i"
            >
              <a
                class="page-link"
                href="javascript:void(0)"
                @click="setPage(i)"
                >{{ i }}</a
              >
            </li>
          </template>
          <template v-if="pagination.totalPages > 4">
            <template v-if="pagination.page - 4 > 4">
              <li class="page-item disabled">
                <span class="page-link">...</span>
              </li>
            </template>
            <!-- pages ~5/page - 3 to maxPage / page + 3 -->
            <template
              v-for="i in paginationRange(
                Math.max(5, pagination.page - 3),
                Math.min(pagination.page + 3, pagination.totalPages)
              )"
            >
              <li
                :class="'page-item' + (pagination.page == i ? ' active' : '')"
                :key="'pagination_top_' + i"
              >
                <a
                  class="page-link"
                  href="javascript:void(0)"
                  @click="setPage(i)"
                  >{{ i }}</a
                >
              </li>
            </template>
            <template
              v-if="pagination.totalPages > Math.max(4, pagination.page) + 3"
            >
              <template v-if="pagination.totalPages - 4 > pagination.page + 4">
                <li class="page-item disabled">
                  <span class="page-link">...</span>
                </li>
              </template>
              <template
                v-for="i in paginationRange(
                  Math.max(pagination.totalPages - 3, pagination.page + 4),
                  pagination.totalPages
                )"
              >
                <li
                  :class="'page-item' + (pagination.page == i ? ' active' : '')"
                  :key="'pagination_top_' + i"
                >
                  <a
                    class="page-link"
                    href="javascript:void(0)"
                    @click="setPage(i)"
                    >{{ i }}</a
                  >
                </li>
              </template>
            </template>
          </template>
          <li class="page-item" v-if="pagination.page < pagination.totalPages">
            <a
              class="page-link"
              href="javascript:void(0)"
              @click="setPage(pagination.page + 1)"
              >Next</a
            >
          </li>
        </ul>
      </div>
    </div>
  </ContentContainer>
</template>

<script>
import { mapGetters } from "vuex";
import ContentContainer from "@/components/templates/ContentContainer";
import moment from "moment";

export default {
  components: { ContentContainer },
  data() {
    return {
      allowWork: false,
      workflowsAreArchivedFilter: false,
      workflows: null,
      workflowsDisplayed: null,
      // statusFilter = default filter
      statusFilter: "all",
      workflowStatusFilters: {
        all: "All",
        finalized: "Published",
        incomplete: "Draft",
        newversion: "Draft Available",
        archived: "Archived"
      },
      pagination: {
        page: 0,
        totalPages: 0,
        limit: 100,
      },
      // list of manager owned workflows, used for followups.
      // will be object of arrays with UUID-Name Pars
      clientManagerWorkflows: {},
      // if no client in context when hitting this page, setting one.
      administratorClientSelection: null,
      schedulingConsumerOptions: [],
      offsetDirectionOptions: [
        { value: -1, text: "Before" },
        { value: 1, text: "After" },
      ],
      offsetChoiceOptions: [
        { value: 0, text: "None" },
        { value: 86400, text: "1 Day" },
        { value: 172800, text: "2 Days" },
        { value: 604800, text: "1 Week" },
        { value: 1209600, text: "2 Weeks" },
        { value: 2419200, text: "4 Weeks" },
        { value: 7257600, text: "12 Weeks" },
        { value: 15768000, text: "26 Weeks" },
        { value: 31536000, text: "1 Year" },
        { value: "custom", text: "Custom" },
      ],
      offsetModifierOptions: [
        { value: 86400, text: "Days" },
        { value: 604800, text: "Weeks" },
        { value: 31536000, text: "Years" },
      ],
      createWorkflowForm: {
        offsetMeta: null,
        offsetChoice: 0,
        offsetModifierAmount: 0,
        offsetModifierDirection: -1,
        offsetModifier: 86400,
        managerID: null,
        followupWorkflowUUID: null,
        title: "",
      },
      loading: false,
      clientOptions: [{ value: null, text: "Please select a client" }],
      assigneeOptions: [
        { value: null, text: "Please select a user to assign this task to" },
      ],
      // if manager, keep track of managerid on a loop
      managerManagerID: null,
      managerNames: [],
      updateWorkflow: null,
      updateWorkflowForm: {
        title: null,
        offsetMeta: null,
        offsetChoice: 0,
        offsetModifierAmount: 0,
        offsetModifierDirection: -1,
        offsetModifier: 86400,
        followupWorkflowUUID: null,
      },
    };
  },
  async mounted() {
    if (!this.getClientInContext) {
      this.returnToDashboard();
      return;
    }

    try {
      this.loading = true;
      this.allowWork = false;
      // reset any children settings that are possible
      await this.$store.commit("setResetBaselineComponent");
      this.resetCreateWorkflowForm();
      this.resetUpdateWorkflowForm();
      if (this.getClientInContext) {
        let templates = await this.getClientNotificationTemplateData;
        if (templates && Array.isArray(templates) && templates.length > 0) {
          let hasEmail = false;
          let hasSMS = false;
          for (let i = 0; i < templates.length && (!hasEmail || !hasSMS); i++) {
            if (templates[i].email_subject != null) {
              hasEmail = true;
            } else {
              hasSMS = true;
            }
          }
          this.allowWork = hasEmail && hasSMS;
        }
        // allow no work if templates not there.
        if (!this.allowWork || !(await this.searchWorkflows("all", true))) {
          return ([this.loading, this.workflowsDisplayed] = [false, []]);
        }
        this.setDatetimeMeta();

        if (this.getUser) {
          const user = this.getUser;

          let userData = null;

          try {
            userData = await this.$store.dispatch("getClientUsers")
          } catch (err) {
            userData = null;
          }
          
          if (userData && Array.isArray(userData) && userData.length > 0) {
            let userManagerID = null;
            for (let i in userData) {
              this.assigneeOptions.push({
                value: userData[i].manager_id,
                text:
                  userData[i].name ||
                  userData[i].email ||
                  userData[i].manager_cognito_id,
              });
              this.managerNames[userData[i].manager_id] =
                userData[i].name ||
                userData[i].email ||
                userData[i].manager_cognito_id;
              if (userData[i].manager_cognito_id == user.attributes.sub) {
                userManagerID = userData[i].manager_id;
              }
            }
            this.managerManagerID = userManagerID || null;
            this.createWorkflowForm.managerID = userManagerID || null;
          }
        }

        await this.$bus.$emit("breadcrumbData", [
          {
            text: "Workflows",
            to: {
              name: "ClientWorkflowOverview",
            },
          },
        ]);
      } else {
        this.returnToDashboard();
      }
    } finally {
      this.loading = false;
    }
  },
  created() {
    this.$bus.$on("closePreview", ($event) => {
      if ($event) {
        [this.preview, this.workflow] = [false, null];
      }
    });
  },
  async beforeDestroy() {
    this.$bus.$off("closePreview");
  },
  computed: {
    ...mapGetters([
      "getClientInContext",
      "getWorkflows",
      "getClients",
      "getWorkflowMeta",
      "getUser",
      "getUserIsManager",
      "getUserIsClientAdmin",
      "getUserIsAdministrator",
      "getClientUserData",
      "getClientNotificationTemplateData",
    ]),
  },
  methods: {
    paginationRange(start, end) {
      // used for pagination to overcome limitations of vue range
      if (start > end) {
        // do not show last page
        return [];
      }
      let rangeArray = [];
      do {
        rangeArray.push(start);
        start++;
      } while (start <= end);
      // return built range array
      return rangeArray;
    },
    setPage(page) {
      if (
        this.loading ||
        isNaN(page) ||
        page < 1 ||
        page > this.pagination.totalPages ||
        !Array.isArray(this.workflows)
      ) {
        return false;
      }
      let slice = page - 1;
      this.pagination.page = page;
      this.workflowsDisplayed = this.workflows.slice(
        slice * this.pagination.limit,
        (slice + 1) * this.pagination.limit
      );
    },
    setDatetimeMeta() {
      if (!this.getClientInContext || !this.getClientInContext.consumer_meta) {
        this.schedulingConsumerOptions = [];
        return;
      }
      let metaNames = Object.keys(this.getClientInContext.consumer_meta);
      if (metaNames.length == 0) {
        this.schedulingConsumerOptions = [];
        return;
      }
      this.schedulingConsumerOptions = [];
      let dates = [{ value: null, disabled: true, text: "-- Date Variables:" }];
      let dateTimes = [
        { value: null, disabled: true, text: "-- Date + Time Variables:" },
      ];
      for (let i in metaNames) {
        if (
          this.getClientInContext.consumer_meta[metaNames[i]].type == "date"
        ) {
          dates.push({
            value: metaNames[i],
            text: this.getClientInContext.consumer_meta[metaNames[i]].name,
          });
        } else if (
          this.getClientInContext.consumer_meta[metaNames[i]].type ==
          "date + time"
        ) {
          dateTimes.push({
            value: metaNames[i],
            text: this.getClientInContext.consumer_meta[metaNames[i]].name,
          });
        }
      }
      if (dates.length == 1 && dateTimes.length == 1) {
        return;
      }
      // will have options for this.
      this.schedulingConsumerOptions.push({
        value: null,
        text: "No Selection",
      });
      if (dates.length > 1) {
        this.schedulingConsumerOptions.push(...dates);
      }
      if (dateTimes.length > 1) {
        this.schedulingConsumerOptions.push(...dateTimes);
      }
    },
    formatWorkflowDate(workflowDate) {
      const date = new Date(workflowDate);
      return moment(date).format("M/D/YYYY");
    },
    async refreshWorkflows() {
      this.clientManagerWorkflows = {};
      if (this.getClientInContext) {
        if (
          await this.$store.dispatch(
            "getActiveWorkflows",
            {
              clientid: this.getClientInContext.client_id,
              workflowIsArchivedFilter: this.workflowsAreArchivedFilter
            }
          )
        ) {
          if (
            this.getWorkflows &&
            Array.isArray(this.getWorkflows) &&
            this.getWorkflows.length > 0
          ) {
            // refresh manager uuid list
            for (let i in this.getWorkflows) {
              if (this.getWorkflows[i].workflow_finalized) {
                if (
                  !Reflect.has(
                    this.clientManagerWorkflows,
                    this.getWorkflows[i].manager_id
                  )
                ) {
                  this.clientManagerWorkflows[
                    this.getWorkflows[i].manager_id
                  ] = [{ value: null, text: "None" }];
                }
                this.clientManagerWorkflows[
                  this.getWorkflows[i].manager_id
                ].push({
                  value: this.getWorkflows[i].workflow_uuid,
                  text: this.getWorkflows[i].workflow_name,
                });
              }
            }
          }
          return true;
        }
      }
      return false;
    },
    initUpdateWorkflowForm(updateWorkflow) {
      if (!updateWorkflow) {
        return this.resetUpdateWorkflowForm();
      }

      this.updateWorkflow = updateWorkflow;
      this.updateWorkflowForm = {
        title: this.updateWorkflow.workflow_name,
        offsetMeta: null,
        offsetChoice: 0,
        offsetModifierAmount: 0,
        offsetModifierDirection: -1,
        offsetModifier: 86400,
        followupWorkflowUUID:
          this.updateWorkflow.workflow_timeline_aftermath_uuid || null,
      };
      if (this.updateWorkflow.workflow_timeline_offset_meta) {
        if (
          this.updateWorkflow.workflow_timeline_offset_meta.meta &&
          this.updateWorkflow.workflow_timeline_offset_meta.meta.type &&
          this.updateWorkflow.workflow_timeline_offset_meta.meta.type ==
            "consumer" &&
          this.updateWorkflow.workflow_timeline_offset_meta.meta.name
        ) {
          this.updateWorkflowForm.offsetMeta = this.updateWorkflow.workflow_timeline_offset_meta.meta.name;
        }
        if (
          this.updateWorkflow.workflow_timeline_offset_meta.offset &&
          !isNaN(this.updateWorkflow.workflow_timeline_offset_meta.offset)
        ) {
          let absOffset = Math.abs(
            this.updateWorkflow.workflow_timeline_offset_meta.offset
          );
          for (let i in this.offsetChoiceOptions) {
            if (this.offsetChoiceOptions[i].value == absOffset) {
              this.updateWorkflowForm.offsetChoice = absOffset;
              break;
            } else if (this.offsetChoiceOptions[i].value > absOffset) {
              break;
            }
          }
          // was not found in pre-list
          if (!this.updateWorkflowForm.offsetChoice) {
            this.updateWorkflowForm.offsetChoice = "custom";
            // attempt to put it in largest bin
            for (let i = this.offsetModifierOptions.length - 1; i >= 0; i--) {
              let modifierSplit =
                absOffset / this.offsetModifierOptions[i].value;
              if (Number.isInteger(modifierSplit)) {
                this.updateWorkflowForm.offsetModifierAmount = modifierSplit;
                this.updateWorkflowForm.offsetModifier = this.offsetModifierOptions[
                  i
                ].value;
                break;
              }
            }

            // if not set, attempt to bin in closest date.
            if (this.updateWorkflowForm.offsetModifierAmount <= 0) {
              this.updateWorkflowForm.offsetModifierAmount = Math.round(
                absOffset / 86400
              );
              this.updateWorkflowForm.offsetModifier = 86400;
            }
          }
          // resolve this last.
          this.updateWorkflowForm.offsetModifierDirection =
            this.updateWorkflow.workflow_timeline_offset_meta.offset < 0
              ? -1
              : 1;
        }
      }
    },
    resetUpdateWorkflowForm() {
      this.updateWorkflow = null;
      this.updateWorkflowForm = {
        title: null,
        offsetMeta: null,
        offsetChoice: 0,
        offsetModifierAmount: 0,
        offsetModifierDirection: -1,
        offsetModifier: 86400,
        followupWorkflowUUID: null,
      };
    },
    async updateWorkflowData() {
      if (!this.updateWorkflow) {
        return this.resetUpdateWorkflowForm();
      }

      if (!this.updateWorkflowForm.title) {
        return this.$store.dispatch("createErrors", "Please enter a name.");
      }

      if (this.updateWorkflowForm.offsetMeta) {
        if (
          !confirm(
            `If you have the workflow scheduled for "${this.updateWorkflowForm.offsetMeta}" - this will require the value be set for a consumer to initialize future timelines. Is this okay?`
          )
        ) {
          return;
        }
      }

      const workflowUpdatePayload = {
        workflowID: this.updateWorkflow.workflow_id,
        workflowUUID: this.updateWorkflow.workflow_uuid,
        workflowVersion: this.updateWorkflow.workflow_version,
        clientID: this.getClientInContext.client_id,
        workflowName: this.updateWorkflowForm.title,
        workflowTimelineOffsetMeta: this.resolveOffsetMeta(
          this.updateWorkflowForm.offsetMeta,
          this.updateWorkflowForm.offsetChoice,
          this.updateWorkflowForm.offsetModifier,
          this.updateWorkflowForm.offsetModifierAmount,
          this.updateWorkflowForm.offsetModifierDirection
        ),
      };

      if (this.updateWorkflowForm.followupWorkflowUUID) {
        // use cognito id if manager, otherwise manager id.
        let workflowCheckKey = this.updateWorkflow.manager_id;
        let foundWorkflowUnderManager = false;
        if (
          Array.isArray(this.clientManagerWorkflows[workflowCheckKey]) &&
          this.clientManagerWorkflows[workflowCheckKey].length > 0
        ) {
          for (let i in this.clientManagerWorkflows[workflowCheckKey]) {
            if (
              this.createWorkflowForm.followupWorkflowUUID ==
              this.clientManagerWorkflows[workflowCheckKey][i].value
            ) {
              foundWorkflowUnderManager = true;
              break;
            }
          }
        }
        if (!foundWorkflowUnderManager) {
          return this.$store.dispatch(
            "createErrors",
            "Cannot assign workflow followup, not found under manager"
          );
        }
        workflowUpdatePayload.workflowTimelineAftermathUUID = this.updateWorkflowForm.followupWorkflowUUID;
      } else {
        workflowUpdatePayload.workflowTimelineAftermathUUID = null;
      }

      try {
        this.loading = true;
        await this.$store.dispatch("updateWorkflow", workflowUpdatePayload);
        this.resetUpdateWorkflowForm();

        await this.searchWorkflows("all", true);
      } finally {
        this.loading = false;
      }
    },
    async createWorkflow() {
      if (this.loading == (this.loading = true)) {
        return false;
      }
      try {
        if (!this.createWorkflowForm.title) {
          return this.$store.dispatch("createErrors", "Please enter a name.");
        }

        // Potential TODO: Expand to custom date?..
        if (this.createWorkflowForm.offsetMeta) {
          if (
            !confirm(
              `If you have the workflow scheduled for "${this.createWorkflowForm.offsetMeta}" - this will require the value be set for a consumer to initialize a timeline for the workflow. Is this okay?`
            )
          ) {
            return;
          }
        }

        const workflowCreatePayload = {
          clientID: this.getClientInContext.client_id,
          workflowName: this.createWorkflowForm.title,
          workflowTimelineOffsetMeta: this.resolveOffsetMeta(
            this.createWorkflowForm.offsetMeta,
            this.createWorkflowForm.offsetChoice,
            this.createWorkflowForm.offsetModifier,
            this.createWorkflowForm.offsetModifierAmount,
            this.createWorkflowForm.offsetModifierDirection
          ),
        };
        if (this.getUserIsAdministrator) {
          if (!this.createWorkflowForm.managerID) {
            return this.$store.dispatch(
              "createErrors",
              "You must assign a user to own this workflow"
            );
          }
          workflowCreatePayload.managerID = this.createWorkflowForm.managerID;
        } else if (
          this.getUserIsClientAdmin &&
          this.createWorkflowForm.managerID
        ) {
          workflowCreatePayload.managerID = this.createWorkflowForm.managerID;
        } else {
          workflowCreatePayload.managerCognitoID = this.getUser.username;
        }

        if (this.createWorkflowForm.followupWorkflowUUID) {
          // use cognito id if manager, otherwise manager id.
          let workflowCheckKey = this.getUserIsManager
            ? this.managerManagerID
            : workflowCreatePayload.managerID;
          let foundWorkflowUnderManager = false;
          if (
            Array.isArray(this.clientManagerWorkflows[workflowCheckKey]) &&
            this.clientManagerWorkflows[workflowCheckKey].length > 0
          ) {
            for (let i in this.clientManagerWorkflows[workflowCheckKey]) {
              if (
                this.createWorkflowForm.followupWorkflowUUID ==
                this.clientManagerWorkflows[workflowCheckKey][i].value
              ) {
                foundWorkflowUnderManager = true;
                break;
              }
            }
          }
          if (!foundWorkflowUnderManager) {
            return this.$store.dispatch(
              "createErrors",
              "Cannot assign workflow followup, not found under manager"
            );
          }
          workflowCreatePayload.workflowTimelineAftermathUUID = this.createWorkflowForm.followupWorkflowUUID;
        }

        const newWorkflow = await this.$store.dispatch(
          "createWorkflow",
          workflowCreatePayload
        );

        if (!newWorkflow) {
          return this.$store.dispatch(
            "createErrors",
            "Workflow was not created, failed to refresh data."
          );
        }
        this.editWorkflow(newWorkflow);
      } finally {
        this.loading = false;
      }
    },
    async duplicateWorkflow(workflow) {
      this.loading = true;

      try {
        let result = await this.$store.dispatch("duplicateWorkflow", workflow);

        if (!result) {
          return this.$store.dispatch(
            "createErrors",
            "There was an error duplicating this workflow, please verify that the workflow is valid then try again."
          );
        } else {
          this.$store.dispatch(
            "createAlerts",
            "Workflow has successfully been duplicated"
          );

          // refresh workflows list
          await this.searchWorkflows("all", true);
        }
      } catch (e) {
        return this.$store.dispatch(
          "createErrors",
          e.message || "Error duplicating workflow, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
    async finalizeWorkflow(workflow) {
      this.loading = true;
      try {
        const meta = {
          workflowID: workflow.workflow_id,
          clientID: this.getClientInContext.client_id,
        };
        let result = await this.$store.dispatch("finalizeWorkflow", meta);

        if (!result) {
          return this.$store.dispatch(
            "createErrors",
            "There was an error finalizing this workflow, please verify that the workflow has both tasks and widgets then try again."
          );
        } else {
          this.$store.dispatch("createAlerts", "Workflow has been finalized!");
        }

        // refresh workflow list.
        await this.searchWorkflows("all", true);
      } catch (e) {
        return this.$store.dispatch(
          "createErrors",
          e.message || "Error finalizing workflow, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
    async archiveWorkflow(workflow) {
      this.loading = true;
      try {
        const payload = {
          workflowUUID: workflow.workflow_uuid,
          clientID: this.getClientInContext.client_id,
        };
        let result = await this.$store.dispatch("archiveWorkflow", payload);

        if (!result) {
          return this.$store.dispatch(
            "createErrors",
            "There was an error archiving this workflow, please try again."
          );
        } else {
          this.$store.dispatch("createAlerts", "Workflow has been archived!");
        }

        // refresh workflow list.
        await this.searchWorkflows("all", true);
      } catch (e) {
        return this.$store.dispatch(
          "createErrors",
          e.message || "Error archiving workflow, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
    async handleNewVersion(workflow) {
      this.loading = true;
      try {
        await this.$store.dispatch("getWorkflowLatest", workflow);
        if (workflow.workflowLatestVersion) {
          this.$router
            .push({
              params: {
                uuid: workflow.workflowLatestVersion.workflow_uuid,
                version: workflow.workflowLatestVersion.workflow_version,
              },
              name: "ClientWorkflowEditNew",
            })
            .catch(() => {});
          if (
            workflow.latest_version !=
            workflow.workflowLatestVersion.workflow_version
          ) {
            this.$store.dispatch(
              "createAlerts",
              "New workflow version has been created"
            );
          }
        }
      } catch (e) {
        return this.$store.dispatch(
          "createErrors",
          e.message || "Error resolving new workflow version, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
    async finalizeNewVersion(workflow) {
      this.loading = true;
      try {
        if (workflow.latest_version == workflow.workflow_version) {
          return this.$store.dispatch(
            "createErrors",
            "Cannot finalize a new version if it has not been created yet"
          );
        }
        if (!workflow.workflowLatestVersion) {
          // resolve it.
          await this.$store.dispatch("getWorkflowLatest", workflow);
        }
        if (!workflow.workflowLatestVersion) {
          return this.$store.dispatch(
            "createErrors",
            "Error retreiving latest workflow version, please try again"
          );
        }

        const meta = {
          workflowID: workflow.workflowLatestVersion.workflow_id,
          clientID: this.getClientInContext.client_id,
        };
        let result = await this.$store.dispatch("finalizeWorkflow", meta);

        if (!result) {
          return this.$store.dispatch(
            "createErrors",
            "There was an error finalizing this workflow, please verify that the workflow has both tasks and widgets then try again."
          );
        }

        // refresh workflow list.
        await this.searchWorkflows("all", true);
      } catch (e) {
        return this.$store.dispatch(
          "createErrors",
          e.message ||
            "Error finalizing new version of workflow, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
    async editWorkflow(workflow) {
      return this.$router
        .push({
          params: {
            uuid: workflow.workflow_uuid,
            version: workflow.workflow_version,
          },
          name: "ClientWorkflowPreview",
        })
        .catch(() => {});
    },
    async removeWorkflow(i) {
      // TODO: API Hook eventually. Currently do not expose
      this.workflows.splice(i, 1);
    },
    resetCreateWorkflowForm() {
      this.createWorkflowForm = {
        offsetMeta: null,
        offsetChoice: 0,
        offsetModifier: 86400,
        offsetModifierAmount: 0,
        offsetModifierDirection: -1,
        managerID: null,
        followupWorkflowUUID: null,
        title: "",
      };
    },
    /**
     * Resolve payload object
     */
    resolveOffsetMeta(
      offsetMeta,
      offsetChoice,
      offsetModifier,
      offsetModifierAmount,
      offsetModifierDirection
    ) {
      if (
        !offsetMeta &&
        (!offsetChoice ||
          (offsetChoice == "custom" &&
            (!offsetModifierAmount || isNaN(offsetModifierAmount))) ||
          (offsetChoice != "custom" && isNaN(offsetChoice)))
      ) {
        return null;
      }

      // force offset to integer via flooring.
      let meta = {
        offset: Math.floor(
          (offsetChoice == "custom"
            ? offsetModifier * offsetModifierAmount
            : offsetChoice) * offsetModifierDirection
        ),
        meta: null,
      };

      if (offsetMeta) {
        meta.meta = {
          type: "consumer",
          key: offsetMeta || null,
        };
      }

      if (meta.offset && !Number.isSafeInteger(meta.offset)) {
        meta.offset =
          meta.offset < 0 ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER;
      }
      return meta;
    },
    async sortWorkflows(workflows) {
      if (!workflows) return [];

      const sortedWorkflows = [...workflows];

      await Promise.all(
        workflows.map(async (workflow, workflowIndex) => {
          if (workflow.latest_version !== workflow.workflow_version) {
            const latestWorkflow = (
              await this.$store.dispatch("getWorkflowLatest", workflow)
            ).workflowLatestVersion;
            sortedWorkflows[workflowIndex].modified_datetime =
              latestWorkflow.modified_datetime;
          }
        })
      );

      return sortedWorkflows.sort((workflowA, workflowB) => {
        return (
          new Date(workflowB.modified_datetime) -
          new Date(workflowA.modified_datetime)
        );
      });
    },
    async searchWorkflows(statusFilter, alreadyLoading) {
      // workflows are always filtered
      // TODO: Full Filtering
      if (!alreadyLoading && this.loading == (this.loading = true)) {
        return false;
      }

      try {
        let newStatusFilter = 'all';
        if (statusFilter && this.workflowStatusFilters[statusFilter]) {
          newStatusFilter = statusFilter;
        }

        this.workflowsAreArchivedFilter = (newStatusFilter === 'archived');
        if (await this.refreshWorkflows()) {
          let filteredWorkflows = [];
          // if status filter is null, don't bother filtering.
          if (newStatusFilter !== 'all' && newStatusFilter !== 'archived') {
            if (Array.isArray(this.getWorkflows)) {
              for (let i in this.getWorkflows) {
                switch (newStatusFilter) {
                  case 'finalized':
                    if (this.getWorkflows[i].workflow_finalized) {
                      filteredWorkflows.push(this.getWorkflows[i]);
                    }
                    break;
                  case 'incomplete':
                    if (!this.getWorkflows[i].workflow_finalized) {
                      filteredWorkflows.push(this.getWorkflows[i]);
                    }
                    break;
                  case 'newversion':
                    if (
                      this.getWorkflows[i].workflow_finalized &&
                      this.getWorkflows[i].workflow_version !=
                        this.getWorkflows[i].latest_version
                    ) {
                      filteredWorkflows.push(this.getWorkflows[i]);
                    }
                    break;
                  
                  default:
                    filteredWorkflows.push(this.getWorkflows[i]);
                    break;
                }
              }
            }
            this.statusFilter = newStatusFilter;
            this.workflows = await this.sortWorkflows(filteredWorkflows);
          } else {
            this.statusFilter = "all";
            this.workflows = await this.sortWorkflows(this.getWorkflows);
          }
          this.pagination.page = 1;
          this.pagination.totalPages = Math.ceil(
            this.workflows.length / this.pagination.limit
          );
          // page is now 0
          this.workflowsDisplayed = this.workflows.slice(
            0,
            this.pagination.limit
          );
        } else {
          this.$store.dispatch(
            "createErrors",
            "Failed to filter workflows, please try again"
          );
          return false;
        }
      } finally {
        if (!alreadyLoading) {
          this.loading = false;
        }
      }
      return true;
    },
    returnToDashboard() {
      this.$bus.$emit("breadcrumbData", []);
      if (this.getUserIsAdministrator) {
        this.$router.push({ name: "ClientInfo" }).catch(() => {});
      } else {
        this.$router.push({ name: "Home" }).catch(() => {});
      }
    },
    clickWorkflow(workflow) {
      if (workflow.workflow_version < workflow.latest_version)
        this.handleNewVersion(workflow);
      else this.editWorkflow(workflow);
    },
  },
};
</script>
